
*{
  margin: 0;
}
body {
  font-family: "poppins";
  color: #444444;
}

a {
  color: #e03a3c;
  text-decoration: none;
}

a:hover {
  color: #e76668;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Raleway", sans-serif;

}
#mycarousel{
  filter: brightness(80%);
  font-size: 24px;
  margin:10px 0 0;
}


.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #e03a3c;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #e65d5f;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}


#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 10px 0;
  width: 100%;
}

#header.header-scrolled {
  padding: 12px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: 60px;
  font-family: "Poppins", sans-serif;
}

#header .logo img {
  max-height: 60px;
}

.get-started-btn {
  margin-left: 30px;
  background: #e03a3c;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}

.get-started-btn:hover {
  background: #111111;
  color: #fff;
}

@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}


.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #111111;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #e03a3c;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #e03a3c;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  color: #111111;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #111111;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #e03a3c;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #e03a3c;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}


#hero   {
  
  width: 100%;
  
  background-size: cover;
  position: relative;
  padding-top: 82px;
  filter: (60%);
  animation: wipe-enter;
  animation-duration: 2s;
}
@keyframes fadeInLeft {
  0% {
     opacity: 0;
     transform: translateZ(200px);
  }
  100% {
     opacity: 1;
     
  }
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h1 {
  margin: 0;
  font-family: poppins;
  font-weight: 600;
  color: #fff;
 filter: brightness(90%);
 animation: wipe-enter;
 animation-duration: 3s;

}

#hero h2 {
  color: #fff;
  margin: 10px 0 0 0;
  font-size: 24px;
  filter: brightness(80%);
  animation: wipe-enter;
  animation-duration: 3s;

}
@keyframes fadeInRight {
  0% {
     opacity: 0;
     transform: translateY(-200px);
  }
  100% {
     opacity: 1;
     
  }
}

@keyframes fadeIn {
  0% {
     opacity: 0;
     transform: translateY(200px);
  }
  100% {
     opacity: 1;
     
  }
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #e03a3c;
  border: 2px solid #e03a3c;
  filter: brightness(80%);
  animation: fadeIn;
  animation-duration: 5s;

}

#hero .btn-get-started:hover {
  background: transparent;
  border-color: #fff;
  margin-left: 0px;

}

@media (max-width: 768px) {
  #hero {
    text-align: center;
    padding-top: 58px;
  }

  #hero h1 {
    font-size: 28px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

section {
  padding: 60px 0;
  overflow: hidden;
  position: relative;
  animation: wipe-enter;
  animation-duration: 5s;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}

.section-title h2 {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e03a3c;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

.section-bg {
  padding: 120px 0;
  color: #fff;
}

.section-bg:before {
  content: "";
  background: #1b1b1b;
  position: absolute;
  bottom: 60px;
  top: 60px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}

.section-bgs {
  padding: 120px 0;
  color: #fff;
}

.section-bgs:before {
  content: "";
  background: #1b1b1b;
  position: absolute;
  bottom: 10px;
  top: 10px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}

.about {
  --background-color: #f4f4f4;
  animation: fadeIn 3s;
  padding: 50px 5%;
}

.about .content h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 20px;
  background: rgba(var(--accent-color-rgb), 0.05);
  color: black;
  border-radius: 7px;
  display: inline-block;
  animation: fadeIn 3s;
}

.about .content h2 {
  font-weight: bold;
  font-size: 1rem;
  color: darkgray;
}

.about .content p:last-child {
  margin-bottom: 0;
  font-size: 1rem;
  color: darkgray;
  font-weight: bold;
}

.about .content .read-more {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 12px 24px;
  border-radius: 5px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.about .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.about .content .read-more:hover {
  background: rgba(var(--accent-color-rgb), 0.8);
  padding-right: 19px;
}

.about .content .read-more:hover i {
  margin-left: 10px;
}

.about .icon-box {
  padding: 50px 40px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease-out;
  background-color: #fff;
}

.about .icon-box i {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 0;
  transition: all 0.4s ease-out;
  background-color: rgba(var(--accent-color-rgb), 0.05);
  color: var(--accent-color);
}

.about .icon-box h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
}

.about .icon-box p {
  margin-bottom: 0;
}

.about .icon-box:hover i {
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.about .icon-boxes .col-md-6:nth-child(2) .icon-box,
.about .icon-boxes .col-md-6:nth-child(4) .icon-box {
  margin-top: -40px;
}

@media (max-width: 768px) {
  .about {
    padding: 20px 10px;
  }

  .about .content h2 {
    font-size: 1.5rem;
  }

  .about .content p:last-child {
    font-size: 0.9rem;
  }

  .about .icon-box {
    padding: 20px;
    margin-top: 20px;
  }

  .about .icon-box i {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }

  .about .icon-box h3 {
    font-size: 20px;
  }

  .about .icon-boxes .col-md-6:nth-child(2) .icon-box,
  .about .icon-boxes .col-md-6:nth-child(4) .icon-box {
    margin-top: 0;
  }
}

#about {
  
  width: 100%;
  height: auto;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 5%;
  animation: fadeIn 5s;
}

.about-text {
  width: 100%;
  text-align: center;
  color: #fff;
  list-style: circle;
}

.about-text h2 {
  font-family: 'Poppins';
  font-size: 2rem;
  color: #000;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.about-text p {
  width: 100%;
  color: #444444;
  margin: 0 auto;
}

.about-text button {
  margin-top: 20px;
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border: none;
  outline: none;
}

.about-text button:hover {
  background-color: rgb(0, 132, 255);
  transition: all ease 0.3s;
  color: #ffffff;
}

.about-image {
  width: 100%;
  text-align: center;
}

.about-image img {
  width: 100%;
  max-width: 700px;
  border-radius: 20px;
  margin-top: 20px;
}

@media (min-width: 769px) {
  #about {
    flex-direction: row;
    justify-content: space-between;
  }

  .about-text {
    width: 45%;
    text-align: left;
  }

  .about-image {
    width: 45%;
  }
}

.tabs .nav-tabs {
  border: 0;
}

.tabs .nav-link {
  border: 1px solid #b9b9b9;
  padding: 15px;
  transition: 0.3s;
  color: #111111;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabs .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.tabs .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.tabs .nav-link:hover {
  color: #e03a3c;
}

.tabs .nav-link.active {
  background: #e03a3c;
  color: #fff;
  border-color: #e03a3c;
}

@media (max-width: 768px) {
  .tabs .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .tabs .nav-link {
    padding: 15px;
  }

  .tabs .nav-link i {
    font-size: 24px;
   
  }
}

.tabs .tab-content {
  margin-top: 30px;
}

.tabs .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.tabs .tab-pane ul {
  list-style: none;
  padding: 0;
}

.tabs .tab-pane ul li {
  padding-bottom: 10px;
}

.tabs .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e03a3c;
}

.tabs .tab-pane p:last-child {
  margin-bottom: 0;
}


.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
  background: #252525;
  transition: 0.3s;
  position: relative;
}

.services .icon-box:hover {
  background: #2b2b2b;
}

.services .icon-box i {
  float: left;
  color: #e03a3c;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #fff;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  text-decoration: underline;
}

.services .icon-box .icon-box:hover h4 a {
  color: #e03a3c;
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  /* font-size: 14px; */
}


.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #e03a3c;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(17, 17, 17, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(17, 17, 17, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #fff;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}


.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e03a3c;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e03a3c;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(17, 17, 17, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}


.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px 0 32px 0;
  border-radius: 4px;
}

.contact .info-box i {
  font-size: 32px;
  color: #e03a3c;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f8d4d5;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  border-radius: 4px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 25px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #111111;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #e03a3c;
  border: 0;
  padding: 10px 32px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #e35052;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



#footer {
  color: #fff;
  font-size: 14px;
  background: #111111;
}

#footer .footer-top {
  padding: 30px 15px;
  background: #1b1b1b;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h5 {
  font-size: 16px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul a {
  color: #aaaaaa;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #fff;
}

#footer .social-links a {
  font-size: 16px;
  padding: 6px;
  margin-right: 4px;

 
}

#footer .social-links a:hover {
  background: #e03a3c;
  
  color: #fff;
}




@media (min-width: 420px) {
  #footer .footer-top {
    padding: 60px 30px;
  }

  #footer .footer-top .footer-contact h5 {
    font-size: 18px;
    display: flex;
  }

  #footer .footer-top .footer-links {
    margin-left: 0;
    display: flex;
  }

  #footer .social-links a {
    font-size: 18px;
    padding: 8px;
  }
}





.flex {
  display: flex;
  align-items: center;
  justify-content: center;
 }
 
 .app {
  &-btn {
   width: 45%;
   max-width: 160px;
   color: #fff;
   margin: 20px 10px;
   text-align: left;
   border-radius: 5px;
   text-decoration: none;
   font-family: "Lucida Grande", sans-serif;
   font-size: 10px;
   text-transform: uppercase;
   &.blu {
    background-color: #101010;
    transition: background-color 0.25s linear;
    &:hover {
     background-color: #454545;
    }
   }
   i {
    width: 20%;
    text-align: center;
    font-size: 28px;
    margin-right: 7px;
   }
   .big-txt {
    font-size: 17px;
    text-transform: capitalize;
   }
  }
 }

 

