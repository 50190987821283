.a1{
    font-size: 22px;
    color: #333;
    text-align: center;
    margin-bottom: 1.4rem;
  }
  
  .wrapper {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .media {
    max-width: 600px;
    max-height: 500px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
  }
  
  .media img {
    max-width: 100%;
    height: 400px;
  

  }
  
  .layer {
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 90%;
    background: #fff;
    color: #151e3f;
    transition: all 0.9s ease;
    .des {
      transition: all 0.9s ease;
      transform: scale(0.1);
    }
  }
  
  .des {
    text-align: center;
    font-size: 15px;
    letter-spacing: 1px;
  }
  
  .media:hover .layer {
    opacity: 0.8;
    width: 90%;
    transition: all 0.5s ease;
    .des {
      transform: scale(1);
      transition: all 0.9s ease;
    }
  }